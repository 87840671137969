import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { debounce, setJoyrideCompleted } from '../../utils';
import { EventTracker } from '../../utils/eventTracker';
import { Actions, Categories, Contexts } from '../constants/trackingEvents';
var JoyrideContext = createContext();
var SCROLL_MARGIN_TOP = 200;
export var JoyrideProvider = function JoyrideProvider(_ref) {
    var children = _ref.children;
    var location = useLocation();
    var _useState = useState(''), _useState2 = _slicedToArray(_useState, 2), pageId = _useState2[0], setPageId = _useState2[1];
    var _useState3 = useState([]), _useState4 = _slicedToArray(_useState3, 2), steps = _useState4[0], setSteps = _useState4[1];
    var _useState5 = useState(''), _useState6 = _slicedToArray(_useState5, 2), scrollClass = _useState6[0], setScrollClass = _useState6[1];
    var _useState7 = useState(false), _useState8 = _slicedToArray(_useState7, 2), run = _useState8[0], setRun = _useState8[1];
    var _useState9 = useState(0), _useState10 = _slicedToArray(_useState9, 2), stepIndex = _useState10[0], setStepIndex = _useState10[1];
    var _useState11 = useState(-1), _useState12 = _slicedToArray(_useState11, 2), handledStep = _useState12[0], setHandledStep = _useState12[1];
    var _useState13 = useState([]), _useState14 = _slicedToArray(_useState13, 2), joyrideSteps = _useState14[0], setJoyrideSteps = _useState14[1];
    var resetData = function resetData() {
        setJoyrideCompleted(pageId);
        setRun(false);
        setStepIndex(0);
        setHandledStep(-1);
    };
    var handleJoyrideCallback = function handleJoyrideCallback(joyrideData) {
        var action = joyrideData.action, index = joyrideData.index, type = joyrideData.type, status = joyrideData.status;
        if (status === STATUS.FINISHED) {
            EventTracker.captureEvent({
                event: Actions.tutorial.completed,
                category: Categories.tutorial,
                payload: {
                    context: Contexts.guide,
                    pageId: pageId
                }
            });
        }
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            resetData();
        }
        else if ([EVENTS.STEP_BEFORE].includes(type)) {
            if (handledStep === index) {
                return;
            }
            var joyrideElement = document.querySelector(joyrideData.step.target);
            if (!joyrideElement || joyrideElement.offsetWidth === 0 || joyrideElement.offsetHeight === 0) {
                var nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
                setStepIndex(nextStep);
                return;
            }
            var targetClass = joyrideData.step.target.includes(scrollClass) ? joyrideData.step.target : "".concat(scrollClass, " ").concat(joyrideData.step.target);
            if (!scrollClass) {
                return;
            }
            var scrollView = document.querySelector(scrollClass);
            var targetView = document.querySelector(targetClass);
            if (!scrollView || !targetView) {
                return;
            }
            var overOffsetTop = targetView.offsetTop - scrollView.offsetTop < scrollView.scrollTop;
            var overOffsetBottom = targetView.offsetTop + targetView.offsetHeight > scrollView.scrollTop + window.innerHeight;
            var scrollOffset = targetView.offsetTop - scrollView.offsetTop - SCROLL_MARGIN_TOP;
            scrollOffset = scrollOffset < 0 ? 0 : scrollOffset;
            if (scrollOffset === Math.round(scrollView.scrollTop)) {
                return;
            }
            if (overOffsetTop || overOffsetBottom && scrollOffset > 0) {
                setHandledStep(index);
                setRun(false);
                var _onScrolled = function onScrolled() {
                    var isEndScrollUp = overOffsetTop && scrollView.scrollTop <= scrollOffset + 1;
                    var isReachedEnd = scrollView.scrollTop >= scrollView.scrollHeight - scrollView.offsetHeight - 1;
                    var isEndScrollDown = overOffsetBottom && (isReachedEnd || scrollView.scrollTop >= scrollOffset - 1);
                    if (isEndScrollUp || isEndScrollDown) {
                        setRun(true);
                        setStepIndex(index);
                        scrollView.removeEventListener('scroll', _onScrolled);
                    }
                };
                scrollView.addEventListener('scroll', _onScrolled);
                scrollView.scrollTo({
                    left: 0,
                    top: scrollOffset,
                    behavior: 'smooth'
                });
            }
        }
        else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if (action === ACTIONS.CLOSE) {
                setRun(false);
            }
            var _nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
            setStepIndex(_nextStep);
        }
    };
    var onGuideItemClicked = function onGuideItemClicked(index) {
        setHandledStep(-1);
        setRun(true);
        setStepIndex(index);
    };
    var updateJoyrideSteps = debounce(function () {
        var filterSteps = steps.map(function (step, index) {
            return _objectSpread(_objectSpread({}, step), {}, {
                index: index
            });
        }).filter(function (step) {
            var target = document.querySelector(step.target);
            return target !== null && target.offsetWidth > 0;
        });
        if (filterSteps.length !== joyrideSteps.length) {
            setJoyrideSteps(filterSteps);
        }
    }, 300);
    useEffect(function () {
        window.addEventListener('resize', updateJoyrideSteps);
        return function (_) {
            window.removeEventListener('resize', updateJoyrideSteps);
        };
    }, []);
    var fullPath = "".concat(location.pathname).concat(location.search);
    useEffect(function () {
        setPageId('');
        setSteps([]);
        setScrollClass('');
        setJoyrideSteps([]);
    }, [fullPath]);
    useEffect(function () {
        updateJoyrideSteps();
    }, [steps]);
    return React.createElement(JoyrideContext.Provider, {
        value: {
            run: run,
            handleJoyrideCallback: handleJoyrideCallback,
            onGuideItemClicked: onGuideItemClicked,
            joyrideSteps: joyrideSteps,
            stepIndex: stepIndex,
            setPageId: setPageId,
            setSteps: setSteps,
            setScrollClass: setScrollClass,
            setRun: setRun,
            updateJoyrideSteps: updateJoyrideSteps
        }
    }, children);
};
JoyrideProvider.propTypes = {
    children: PropTypes.node.isRequired
};
export var useJoyrideContext = function useJoyrideContext() {
    return useContext(JoyrideContext);
};
