import React from 'react';
import { theme, Button, H5, P, Icon, Block } from '@quizrr/qcl';
import { Toaster, ToastBar, toast } from 'react-hot-toast';
import { lighten } from 'polished';
var CustomizedToaster = function CustomizedToaster() {
    var getToastIcon = function getToastIcon(toastType) {
        switch (toastType) {
            case 'success':
                return React.createElement(Icon, {
                    icon: "check",
                    color: "white"
                });
            case 'error':
                return React.createElement(Icon, {
                    icon: "exclamation-triangle",
                    color: "white"
                });
            case 'loading':
                return React.createElement(Icon, {
                    icon: "spinner",
                    size: "baseDown1",
                    spin: true
                });
            default:
                return null;
        }
    };
    return React.createElement(Toaster, {
        position: "top-right",
        toastOptions: {
            success: {
                style: {
                    border: "1px solid ".concat(theme.colors.green500),
                    backgroundColor: lighten(0.08, theme.colors.green500)
                }
            },
            error: {
                style: {
                    border: "1px solid ".concat(theme.colors.warning),
                    backgroundColor: lighten(0.08, theme.colors.warning)
                }
            },
            loading: {
                style: {
                    border: "1px solid ".concat(theme.colors.gray400),
                    backgroundColor: lighten(0.1, theme.colors.gray400)
                }
            },
            ariaProps: {
                role: 'status',
                'aria-live': 'polite'
            },
            style: {
                minWidth: 300,
                display: 'block'
            }
        }
    }, function (t) {
        return React.createElement(ToastBar, {
            toast: t
        }, function (_ref) {
            var message = _ref.message;
            var _message$props$childr = message.props.children, title = _message$props$childr.title, text = _message$props$childr.text;
            var isLoading = t.type === 'loading';
            return React.createElement(React.Fragment, null, React.createElement(Block, {
                display: "flex",
                alignItems: "center"
            }, React.createElement(Block, null, getToastIcon(t.type)), !isLoading && React.createElement(Button, {
                onClick: function onClick() {
                    return toast.dismiss(t.id);
                },
                ml: "auto",
                size: "small"
            }, React.createElement(Icon, {
                icon: "times",
                size: "baseDown1"
            }))), React.createElement(Block, {
                py: "baseUnit"
            }, title && React.createElement(H5, {
                color: isLoading ? 'baseFont' : 'white',
                mb: "baseDown2"
            }, title), text && React.createElement(P, {
                color: isLoading ? 'baseFont' : 'white',
                mb: "0"
            }, text)));
        });
    });
};
export default CustomizedToaster;
