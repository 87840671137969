import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _inherits from "@babel/runtime/helpers/inherits";
import _wrapNativeSuper from "@babel/runtime/helpers/wrapNativeSuper";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _callSuper(t, o, e) { return o = _getPrototypeOf(o), _possibleConstructorReturn(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], _getPrototypeOf(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try {
    var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () { }));
}
catch (t) { } return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
import * as Sentry from '@sentry/react';
export var AppError = function (_Error) {
    function AppError(name, message) {
        var _this;
        var captureException = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        var extras = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
        _classCallCheck(this, AppError);
        _this = _callSuper(this, AppError, [message]);
        _this.name = name || _this.constructor.name;
        if (captureException) {
            Sentry.captureException(_this, function (scope) {
                scope.setExtras(_objectSpread({
                    url: window.location.href
                }, extras));
                return scope;
            });
        }
        return _this;
    }
    _inherits(AppError, _Error);
    return _createClass(AppError, null, [{
            key: "captureException",
            value: function captureException() {
                for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
                    args[_key] = arguments[_key];
                }
                return Reflect.construct(this, args);
            }
        }]);
}(_wrapNativeSuper(Error));
