export var MemberTrainingStatus = function (MemberTrainingStatus) {
    MemberTrainingStatus["NOT_STARTED"] = "not_started";
    MemberTrainingStatus["IN_PROGRESS"] = "in_progress";
    MemberTrainingStatus["COMPLETED"] = "completed";
    MemberTrainingStatus["INVITE_PENDING"] = "invite_pending";
    MemberTrainingStatus["REQUEST_PENDING"] = "request_pending";
    MemberTrainingStatus["REVOKED"] = "revoked";
    MemberTrainingStatus["REGISTERED"] = "registered";
    return MemberTrainingStatus;
}({});
export var MAX_INTEGER_NUMBER = 65536;
export var TeamSize = Object.freeze({
    10: '0 - 10 ',
    50: '11 - 50',
    200: '51 - 200',
    500: '201 - 500',
    1000: '501 - 1000',
    65536: '1000+'
});
