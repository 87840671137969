import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import { ApolloConsumer } from '@apollo/client';
export var withApolloClient = function withApolloClient(Component) {
    return function (_ref) {
        var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
        return React.createElement(ApolloConsumer, null, function (client) {
            return React.createElement(Component, _extends({
                apolloClient: client
            }, props));
        });
    };
};
