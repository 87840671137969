import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
export var getActiveTrainings = function getActiveTrainings(accessibleQRCodes) {
    var trainings = [];
    (accessibleQRCodes || []).filter(function (qrCode) {
        return !(qrCode !== null && qrCode !== void 0 && qrCode.revoked);
    }).forEach(function (qrCode) {
        var _qrCode$config, _qrCode$config$traini;
        (_qrCode$config = qrCode.config) === null || _qrCode$config === void 0 ? void 0 : (_qrCode$config$traini = _qrCode$config.trainingSeriesList) === null || _qrCode$config$traini === void 0 ? void 0 : _qrCode$config$traini.forEach(function (trainingSeries) {
            if (!(trainingSeries !== null && trainingSeries !== void 0 && trainingSeries.revoked)) {
                trainings.push(_objectSpread(_objectSpread({}, trainingSeries), {}, {
                    qrCodeId: qrCode._id,
                    programId: trainingSeries.programId
                }));
            }
        });
    });
    return trainings;
};
