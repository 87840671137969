import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme, siteColors, GlobalStyle } from '@quizrr/qcl';
import * as Fathom from 'fathom-client';
import { ErrorBoundary } from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import { client } from '../data/apollo';
import AppProviders from '../AppProviders';
import Toaster from '../Toaster';
import GlobalStyleVietnamese from '../components/GlobalStyleVietnamese';
import Router from './Router';
Fathom.load(config.fathomSiteId, {
    spa: 'auto'
});
var Root = function Root() {
    return React.createElement(ThemeProvider, {
        theme: _objectSpread(_objectSpread({}, theme), {}, {
            colors: _objectSpread(_objectSpread({}, theme.colors), {}, {
                baseFont: siteColors.gray700
            })
        })
    }, React.createElement(Toaster, null), React.createElement(AppProviders, null, React.createElement(ErrorBoundary, null, React.createElement(ApolloProvider, {
        client: client
    }, React.createElement(GlobalStyle, null), React.createElement(GlobalStyleVietnamese, null), React.createElement(Router, null)))));
};
export default Root;
