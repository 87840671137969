import _createClass from "@babel/runtime/helpers/createClass";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { getCookieConsentValue } from 'react-cookie-consent';
import posthog from 'posthog-js';
import featureEnabled from '../featureFlags';
import { COOKIE_NAME_QUIZRR_CONSENT } from '../../data/constants';
var PostHog = _createClass(function PostHog() {
    var _this = this;
    _classCallCheck(this, PostHog);
    _defineProperty(this, "_isInitialized", false);
    _defineProperty(this, "posthogApiKey", '');
    _defineProperty(this, "reset", function () {
        _this._isInitialized = false;
    });
    _defineProperty(this, "_isReadyForUse", function () {
        if (!featureEnabled('analyticsTracking')) {
            return false;
        }
        if (!_this._isInitialized) {
            return false;
        }
        return true;
    });
    _defineProperty(this, "_loadPostHog", function () {
        posthog.init(_this.posthogApiKey, {
            api_host: 'https://eu.posthog.com',
            capture_pageview: false,
            disable_persistence: true
        });
        _this._isInitialized = true;
    });
    _defineProperty(this, "initialize", function (posthogApiKey) {
        if (!featureEnabled('analyticsTracking')) {
            return;
        }
        _this.posthogApiKey = posthogApiKey;
        _this._loadPostHog();
        if (getCookieConsentValue(COOKIE_NAME_QUIZRR_CONSENT) === 'true') {
            _this.grantConsent();
        }
    });
    _defineProperty(this, "grantConsent", function () {
        var _posthog$persistence;
        posthog.set_config({
            disable_persistence: false
        });
        posthog.opt_in_capturing();
        (_posthog$persistence = posthog.persistence) === null || _posthog$persistence === void 0 ? void 0 : _posthog$persistence.save();
    });
    _defineProperty(this, "identify", function (user) {
        if (!_this._isReadyForUse()) {
            return;
        }
        posthog.identify(user.id, {
            role: user.role,
            email: user.email,
            accounts: user.accounts.map(function (account) {
                return account._id.toString();
            })
        });
    });
    _defineProperty(this, "resetUser", function () {
        posthog.reset();
    });
    _defineProperty(this, "pageView", function () {
        if (!_this._isReadyForUse()) {
            return;
        }
        posthog.capture('$pageview');
    });
    _defineProperty(this, "capture", function (event, payload) {
        if (!_this._isReadyForUse()) {
            return;
        }
        posthog.capture(event, payload);
    });
    this.reset();
});
export default new PostHog();
