import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
export function create() {
    return new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    stats: {
                        merge: function merge() {
                            var existing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
                            var incoming = arguments.length > 1 ? arguments[1] : undefined;
                            return _objectSpread(_objectSpread({}, existing), incoming);
                        }
                    },
                    statsGo: {
                        merge: function merge() {
                            var existing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
                            var incoming = arguments.length > 1 ? arguments[1] : undefined;
                            return _objectSpread(_objectSpread({}, existing), incoming);
                        }
                    }
                }
            },
            Filter: {
                keyFields: [],
                fields: {
                    factories: {
                        merge: false
                    }
                }
            },
            FilterGo: {
                keyFields: []
            },
            FilterOverview: {
                keyFields: [],
                fields: {
                    factories: {
                        merge: false
                    }
                }
            },
            User: {
                keyFields: function keyFields(object) {
                    return defaultDataIdFromObject(object);
                },
                fields: {
                    followedFactories: {
                        merge: false
                    }
                }
            },
            Organization: {
                keyFields: function keyFields(object) {
                    return defaultDataIdFromObject(object);
                }
            },
            Brand: {
                keyFields: function keyFields(object) {
                    return defaultDataIdFromObject(object);
                }
            },
            Factory: {
                keyFields: function keyFields(object) {
                    return defaultDataIdFromObject(object);
                }
            },
            TrainingPlan: {
                keyFields: function keyFields(object) {
                    return defaultDataIdFromObject(object);
                }
            }
        },
        dataIdFromObject: function dataIdFromObject() {
            return false;
        }
    });
}
