import React from 'react';
import PropTypes from 'prop-types';
var I18nLink = function I18nLink(_ref) {
    var link = _ref.link, children = _ref.children, target = _ref.target;
    return React.createElement("a", {
        href: link,
        target: target
    }, children);
};
I18nLink.defaultProps = {
    target: '_blank',
    children: null
};
I18nLink.propTypes = {
    target: PropTypes.string,
    link: PropTypes.string.isRequired,
    children: PropTypes.node
};
export default I18nLink;
