import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { Loading } from '@quizrr/qcl';
import { useAuth } from '../data/contexts/AuthContext';
import { canAccessDashboard } from '../utils/featureFlags';
var RequireAuth = function RequireAuth(_ref) {
    var children = _ref.children;
    var location = useLocation();
    var _useAuth = useAuth(), _useAuth$state = _useAuth.state, isAuthenticated = _useAuth$state.isAuthenticated, isAuthenticating = _useAuth$state.isAuthenticating, user = _useAuth$state.user;
    if (isAuthenticating) {
        return React.createElement(Loading, {
            height: "100%"
        });
    }
    return isAuthenticated && canAccessDashboard(user.role) ? children : React.createElement(Navigate, {
        to: "/signin",
        state: {
            next: location.pathname
        },
        replace: true
    });
};
RequireAuth.propTypes = {
    children: PropTypes.node.isRequired
};
export default RequireAuth;
