import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { CustomGraphQLError } from '../../errors/customGraphqlError';
import { checkIsAuthenticated, getCurrentAccessToken } from '../../utils';
import * as Filter from '../filter';
import * as Cache from './cache';
var _config = config, readServiceUrl = _config.readServiceUrl;
var httpLink = createUploadLink({
    uri: "".concat(readServiceUrl, "/graphql")
});
var setAuthorizationLink = setContext(function (request, previousContext) {
    return {
        headers: _objectSpread(_objectSpread({}, previousContext.headers), checkIsAuthenticated() && {
            authorization: "Bearer ".concat(getCurrentAccessToken())
        })
    };
});
var graphQLErrorHandlerLink = onError(function (errorResponse) {
    if (errorResponse.graphQLErrors) {
        CustomGraphQLError.captureException(errorResponse);
    }
});
export var defaultFilterState = {
    filters: _objectSpread({
        __typename: 'Filter'
    }, Filter.defaultValues()),
    filtersGo: _objectSpread({
        __typename: 'FilterGo'
    }, Filter.defaultValuesGo()),
    filtersOverview: _objectSpread({
        __typename: 'FilterOverview'
    }, Filter.defaultValuesOverview())
};
export var client = new ApolloClient({
    link: graphQLErrorHandlerLink.concat(setAuthorizationLink).concat(httpLink),
    cache: Cache.create(),
    clientState: {
        defaults: _objectSpread({}, defaultFilterState),
        resolvers: {}
    }
});
