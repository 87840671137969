import { Features, SignUpSteps } from '../data/constants';
import { AccountType, UserRole } from '../data/enums';
export var checkShouldAskFacilityBrandId = function checkShouldAskFacilityBrandId(_ref) {
    var _signupFeaturePackage, _facilityOwnerAccount;
    var user = _ref.user, signupFeaturePackage = _ref.signupFeaturePackage;
    if (!user || !signupFeaturePackage || !((_signupFeaturePackage = signupFeaturePackage.signupSteps) !== null && _signupFeaturePackage !== void 0 && _signupFeaturePackage.some(function (step) {
        var _step$features;
        return step.id === SignUpSteps.create_factory && ((_step$features = step.features) === null || _step$features === void 0 ? void 0 : _step$features.some(function (f) {
            return f === Features.AskFacilityBrandId;
        }));
    }))) {
        return false;
    }
    var accounts = user.accounts, featurePackage = user.featurePackage, factories = user.factories, role = user.role;
    var isOwnerUser = role === UserRole.ACCOUNT_OWNER;
    var facilityOwnerAccount = isOwnerUser && (!featurePackage || (featurePackage === null || featurePackage === void 0 ? void 0 : featurePackage.accountType) === AccountType.TRAINING_FACILITY) ? accounts.find(function (acc) {
        var _acc$factories;
        return (_acc$factories = acc.factories) === null || _acc$factories === void 0 ? void 0 : _acc$factories.length;
    }) : undefined;
    if (!facilityOwnerAccount) {
        return false;
    }
    var hasFacilityBrandId = (_facilityOwnerAccount = facilityOwnerAccount.factories) === null || _facilityOwnerAccount === void 0 ? void 0 : _facilityOwnerAccount.every(function (facilityId) {
        return factories.some(function (f) {
            var _f$facilityBrandIds;
            return f._id.toString() === facilityId.toString() && ((_f$facilityBrandIds = f.facilityBrandIds) === null || _f$facilityBrandIds === void 0 ? void 0 : _f$facilityBrandIds.some(function (fbi) {
                return fbi.id && signupFeaturePackage.brand === fbi.brand;
            }));
        });
    });
    return !hasFacilityBrandId;
};
export var getAskUsesLaborAgentsForTrainingIds = function getAskUsesLaborAgentsForTrainingIds(_ref2) {
    var _signupFeaturePackage2;
    var user = _ref2.user, signupFeaturePackage = _ref2.signupFeaturePackage;
    if (!user || !signupFeaturePackage || !((_signupFeaturePackage2 = signupFeaturePackage.signupSteps) !== null && _signupFeaturePackage2 !== void 0 && _signupFeaturePackage2.some(function (step) {
        var _step$features2;
        return step.id === SignUpSteps.create_factory && ((_step$features2 = step.features) === null || _step$features2 === void 0 ? void 0 : _step$features2.some(function (f) {
            return f === Features.AskFacilityLaborAgentUsage;
        }));
    }))) {
        return [];
    }
    var accounts = user.accounts, featurePackage = user.featurePackage, role = user.role;
    var isOwnerUser = role === UserRole.ACCOUNT_OWNER;
    var facilityOwnerAccount = isOwnerUser && (!featurePackage || (featurePackage === null || featurePackage === void 0 ? void 0 : featurePackage.accountType) === AccountType.TRAINING_FACILITY) ? accounts.find(function (acc) {
        var _acc$factories2;
        return (_acc$factories2 = acc.factories) === null || _acc$factories2 === void 0 ? void 0 : _acc$factories2.length;
    }) : undefined;
    if (!facilityOwnerAccount) {
        return [];
    }
    return askUsesLaborAgentsForTrainingIds(signupFeaturePackage, facilityOwnerAccount);
};
export var shouldAskAdditionalInformationStep = function shouldAskAdditionalInformationStep(signupFeaturePackage) {
    var _signupFeaturePackage3;
    return signupFeaturePackage === null || signupFeaturePackage === void 0 ? void 0 : (_signupFeaturePackage3 = signupFeaturePackage.signupSteps) === null || _signupFeaturePackage3 === void 0 ? void 0 : _signupFeaturePackage3.some(function (step) {
        return step.id === SignUpSteps.ask_additional_information;
    });
};
var askUsesLaborAgentsForTrainingIds = function askUsesLaborAgentsForTrainingIds(signupFeaturePackage, facilityOwnerAccount) {
    var _signupFeaturePackage4, _signupFeaturePackage5, _signupFeaturePackage6;
    var signupStepCreateFactory = (_signupFeaturePackage4 = signupFeaturePackage.signupSteps) === null || _signupFeaturePackage4 === void 0 ? void 0 : (_signupFeaturePackage5 = _signupFeaturePackage4.find(function (step) {
        return step.id === SignUpSteps.create_factory;
    })) === null || _signupFeaturePackage5 === void 0 ? void 0 : (_signupFeaturePackage6 = _signupFeaturePackage5.features) === null || _signupFeaturePackage6 === void 0 ? void 0 : _signupFeaturePackage6.some(function (id) {
        return id === Features.AskFacilityLaborAgentUsage;
    });
    var trainingIds = signupStepCreateFactory ? signupFeaturePackage.trainingIds : [];
    if (!(facilityOwnerAccount !== null && facilityOwnerAccount !== void 0 && facilityOwnerAccount.usesLaborAgentsForTrainingIds)) {
        return trainingIds;
    }
    return trainingIds.filter(function (trainingId) {
        var _facilityOwnerAccount2;
        return !((_facilityOwnerAccount2 = facilityOwnerAccount.usesLaborAgentsForTrainingIds) !== null && _facilityOwnerAccount2 !== void 0 && _facilityOwnerAccount2.includes(trainingId));
    });
};
