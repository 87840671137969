import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import { gql } from '@apollo/client';
export default {
    byGender: gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment ByGenderFragment on ByGender {\n      male\n      female\n      unknown\n      other\n      total\n    }\n  "]))),
    byJobLevel: gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment ByJobLevelFragment on ByJobLevel {\n      manager\n      midManager\n      worker\n      trainer\n      laborAgentStaff\n      laborAgentOther\n      laborAgentManagement\n      operator\n      janitorialTechnician\n      technician\n      janitor\n      nonFacilityWorker\n      total\n    }\n  "]))),
    performance: gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    fragment PerformanceFragment on Performance {\n      correctAnswersCount {\n        total\n      }\n      answersCount {\n        total\n      }\n    }\n  "]))),
    performanceByJobLevel: gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    fragment PerformanceByJobLevelFragment on PerformanceByJobLevel {\n      correctAnswersCount {\n        total\n      }\n      answersCount {\n        total\n      }\n    }\n  "]))),
    byAge: gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    fragment ByAgeFragment on ByAgeByJobLevel {\n      ageRange {\n        fromAge\n        untilAge\n      }\n      byJobLevel {\n        ...ByJobLevelFragment\n      }\n    }\n  "]))),
    byLanguage: gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    fragment ByLanguageFragment on ByLanguage {\n      key\n      total\n    }\n  "]))),
    byCountry: gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    fragment ByCountryFragment on ByCountry {\n      key\n      total\n    }\n  "]))),
    byDate: gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    fragment ByDateFragment on ByDateByJobLevel {\n      date {\n        year\n        month\n        week\n        day\n      }\n      byJobLevel {\n        ...ByJobLevelFragment\n      }\n    }\n  "])))
};
