import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import moment from 'moment';
import { FilterValues as _jsdoc_FilterValues, DynamicView, isMultiValueFilter } from './dynamicView';
var defaultStaticFilterValues = {
    prevFromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    fromDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    untilDate: moment().format('YYYY-MM-DD'),
    aggregation: 'month',
    questionsVersion: null,
    learningModule: null
};
export function defaultValues() {
    return _objectSpread(_objectSpread({}, defaultStaticFilterValues), {}, {
        retailStore: null,
        supplierCategory: null
    }, DynamicView["default"]().filters().defaultValues());
}
export function defaultValuesGo() {
    return _objectSpread(_objectSpread({}, defaultStaticFilterValues), DynamicView.defaultGo().filters().defaultValues());
}
export function defaultValuesOverview() {
    return _objectSpread(_objectSpread({}, defaultStaticFilterValues), {}, {
        includeInvalidated: false,
        includeTestFactories: false
    }, DynamicView.defaultOverview().filters().defaultValues());
}
export function toQueryArgs(filterValues) {
    var factoryIds = filterValues.factoryIds, fromDate = filterValues.fromDate, untilDate = filterValues.untilDate, market = filterValues.market, facilityType = filterValues.facilityType, learningModule = filterValues.learningModule, retailStore = filterValues.retailStore, baselineCategory = filterValues.baselineCategory, supplierCategory = filterValues.supplierCategory;
    return _objectSpread({
        factory: factoryIds,
        fromDate: fromDate,
        untilDate: untilDate,
        market: Array.isArray(market) ? market : [market],
        facilityType: Array.isArray(facilityType) ? facilityType : [facilityType],
        learningModule: learningModule,
        retailStore: retailStore,
        baselineCategory: baselineCategory,
        supplierCategory: supplierCategory
    }, DynamicView["default"]().filters().state().reduce(function (acc, filter) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, filter.name, isMultiValueFilter(filter) && !Array.isArray(filterValues[filter.name]) ? [filterValues[filter.name]] : filterValues[filter.name]));
    }, {}));
}
export function toQueryArgsGo(filterValues) {
    var _DynamicView$defaultG;
    var factoryIds = filterValues.factoryIds, fromDate = filterValues.fromDate, untilDate = filterValues.untilDate;
    return _objectSpread({
        factory: factoryIds,
        fromDate: fromDate,
        untilDate: untilDate
    }, (_DynamicView$defaultG = DynamicView.defaultGo().state().filters) === null || _DynamicView$defaultG === void 0 ? void 0 : _DynamicView$defaultG.reduce(function (acc, filter) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, filter.name, isMultiValueFilter(filter) && !Array.isArray(filterValues[filter.name]) ? [filterValues[filter.name]] : filterValues[filter.name]));
    }, {}));
}
export function clearDependentValues(values, dependentFilterNames) {
    var updatedValues = _objectSpread({}, values);
    dependentFilterNames.forEach(function (name) {
        updatedValues[name] = null;
    });
    return updatedValues;
}
export function isEmpty(value) {
    return value === null || value === undefined || value === '' || value === 'all' || Array.isArray(value) && value.some(function (v) {
        return v === null || v === undefined || v === '' || v === 'all';
    });
}
