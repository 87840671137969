import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import moment from 'moment';
import getRequiredSessionsPerDay from './getRequiredSessionsPerDay';
import { getDateInfoByAggregation } from './dateAggregation';
var getDaysInDateRange = function getDaysInDateRange(_ref) {
    var fromMoment = _ref.fromMoment, untilMoment = _ref.untilMoment, _ref$excludedDates = _ref.excludedDates, excludedDates = _ref$excludedDates === void 0 ? [] : _ref$excludedDates;
    var trainingPlanDateRange = [];
    var currentMoment = fromMoment.clone();
    while (currentMoment.isSameOrBefore(untilMoment, 'day')) {
        var currentDate = currentMoment.format('YYYY-MM-DD');
        if (!excludedDates.includes(currentDate)) {
            trainingPlanDateRange.push(currentDate);
        }
        currentMoment.add(1, 'day');
    }
    return trainingPlanDateRange;
};
var getRequiredSessions = function getRequiredSessions(start, end, excludedDateRanges, requiredSessionNumber) {
    var period = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'month';
    var processingPeriod = period === 'week' ? 'isoWeek' : period;
    var requiredSessionsPerDay = getRequiredSessionsPerDay(start, end, excludedDateRanges, requiredSessionNumber);
    var requiredSessions = [{}];
    var prevMoment;
    var currentMoment = moment(start);
    var untilMoment = moment(end);
    var excludedDates = excludedDateRanges.flatMap(function (_ref2) {
        var fromDate = _ref2.fromDate, untilDate = _ref2.untilDate;
        var formatDates = [];
        var excludedDateFromMoment = moment(moment(fromDate).format('YYYY-MM-DD'));
        var excludedDateUntilMoment = moment(moment(untilDate).format('YYYY-MM-DD'));
        while (excludedDateFromMoment.isSameOrBefore(excludedDateUntilMoment, 'day')) {
            formatDates.push(excludedDateFromMoment.format('YYYY-MM-DD'));
            excludedDateFromMoment.add(1, 'day');
        }
        return formatDates;
    });
    while (currentMoment.isSameOrBefore(untilMoment, processingPeriod)) {
        var endOfPeriodMoment = currentMoment.isSame(untilMoment, processingPeriod) ? untilMoment : currentMoment.clone().endOf(processingPeriod);
        var trainingDaysInPeriod = getDaysInDateRange({
            fromMoment: currentMoment,
            untilMoment: endOfPeriodMoment,
            excludedDates: excludedDates
        });
        var value = Math.ceil(trainingDaysInPeriod.length * requiredSessionsPerDay);
        var name = '';
        var isNewYear = prevMoment && prevMoment.year() !== currentMoment.year();
        var isNewMonth = prevMoment && prevMoment.month() !== currentMoment.month();
        if (processingPeriod === 'day') {
            name = currentMoment.format(isNewMonth ? 'MMM DD' : 'DD');
        }
        else if (processingPeriod === 'month') {
            name = currentMoment.format(isNewYear ? 'MMM, YYYY' : 'MMM');
        }
        else if (processingPeriod === 'isoWeek') {
            name = "W".concat(requiredSessions.length);
        }
        else {
            name = currentMoment.format('YYYY');
        }
        requiredSessions.push({
            name: name,
            value: value,
            date: getDateInfoByAggregation(currentMoment.format('YYYY-MM-DD'), period)
        });
        prevMoment = currentMoment.clone();
        currentMoment.add(1, processingPeriod).startOf(processingPeriod);
    }
    requiredSessions = requiredSessions.reduce(function (prev, cur, i) {
        if (!prev.length) {
            return [cur];
        }
        var currentVal = cur;
        currentVal.value = Math.min((prev[i - 1].value || 0) + cur.value, requiredSessionNumber);
        return [].concat(_toConsumableArray(prev), [currentVal]);
    }, []);
    return requiredSessions;
};
export default getRequiredSessions;
