import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import '@babel/polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import Bowser from 'bowser';
import Root from './routes';
import { DeviceInfo as _jsdoc_DeviceInfo, InternalEventTracker, PostHog } from './utils/eventTracker';
import { getAccessTokenFromHash } from './data/contexts/AuthContext';
var _config = config, sentry = _config.sentry, npmPackageName = _config.npmPackageName, npmPackageVersion = _config.npmPackageVersion, posthogApiKey = _config.posthogApiKey;
var nextUrl = '';
if (window.location.hash) {
    nextUrl = getAccessTokenFromHash(window.location.hash);
}
if (nextUrl) {
    window.location = nextUrl;
}
else {
    Sentry.init(_objectSpread(_objectSpread({}, sentry), {}, {
        release: "".concat(npmPackageName, "@").concat(npmPackageVersion),
        integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()]
    }));
    var _Bowser$getParser$get = Bowser.getParser(window.navigator.userAgent).getResult(), browser = _Bowser$getParser$get.browser, os = _Bowser$getParser$get.os, platform = _Bowser$getParser$get.platform;
    var device = {
        model: platform.model,
        appVersion: npmPackageVersion,
        operatingSystem: os.name,
        osVersion: os.version,
        manufacturer: platform.vendor,
        browserName: browser.name,
        browserVersion: browser.version,
        type: platform.type,
        buildType: 'web'
    };
    InternalEventTracker.setContext({
        service: npmPackageName,
        device: device,
        correlationId: uuidv4()
    });
    PostHog.initialize(posthogApiKey);
    var container = document.getElementById('app');
    var root = createRoot(container);
    root.render(React.createElement(Root, null));
}
