import moment from 'moment';
export var getAggregation = function getAggregation(dateInfo) {
    var day = dateInfo.day, week = dateInfo.week, month = dateInfo.month, year = dateInfo.year;
    if (week && year) {
        return 'week';
    }
    if (day && month && year) {
        return 'day';
    }
    if (month && year) {
        return 'month';
    }
    return 'year';
};
export var getDateWithAggregation = function getDateWithAggregation(dateInfo, aggregation) {
    var day = dateInfo.day, week = dateInfo.week, month = dateInfo.month, year = dateInfo.year;
    switch (aggregation) {
        case 'day':
            return moment([year, month - 1, day]);
        case 'week':
            return moment().isoWeekYear(year).isoWeek(week);
        case 'month':
            return moment([year, month - 1]);
        default:
            return moment([year]);
    }
};
export var getDateInfoByAggregation = function getDateInfoByAggregation(dateString, aggregation) {
    var momentInfo = moment(dateString);
    switch (aggregation) {
        case 'day':
            return {
                day: momentInfo.date(),
                month: momentInfo.month() + 1,
                year: momentInfo.year()
            };
        case 'week':
            return {
                week: momentInfo.isoWeek(),
                year: momentInfo.isoWeekYear()
            };
        case 'month':
            return {
                month: momentInfo.month() + 1,
                year: momentInfo.year()
            };
        default:
            return {
                year: momentInfo.year()
            };
    }
};
export var getAvailableAggregations = function getAvailableAggregations(fromDate, untilDate) {
    var monthsDiff = untilDate.diff(fromDate, 'month', true);
    if (Number.isNaN(monthsDiff)) {
        return [{
                value: 'month',
                selected: false
            }, {
                value: 'year',
                selected: true
            }];
    }
    if (monthsDiff <= 1) {
        return [{
                value: 'day',
                selected: true
            }];
    }
    if (monthsDiff <= 12) {
        return [{
                value: 'day',
                selected: false
            }, {
                value: 'week',
                selected: false
            }, {
                value: 'month',
                selected: true
            }];
    }
    if (monthsDiff <= 24) {
        return [{
                value: 'week',
                selected: false
            }, {
                value: 'month',
                selected: true
            }, {
                value: 'year',
                selected: false
            }];
    }
    return [{
            value: 'month',
            selected: false
        }, {
            value: 'year',
            selected: true
        }];
};
export var getListItemsBetweenDates = function getListItemsBetweenDates(fromDate, untilDate, aggregation) {
    var list = [];
    var updatedFromDate = fromDate.startOf(aggregation);
    var dateFormat;
    switch (aggregation) {
        case 'day':
            dateFormat = 'YYYY-MM-DD';
            break;
        case 'week':
            dateFormat = 'GGGG-WW';
            break;
        case 'month':
            dateFormat = 'YYYY-MM';
            break;
        default:
            dateFormat = 'YYYY';
            break;
    }
    while (updatedFromDate.isSameOrBefore(untilDate)) {
        list.push(updatedFromDate.format(dateFormat));
        updatedFromDate = updatedFromDate.add(1, aggregation).startOf(aggregation);
    }
    return list;
};
