import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import React, { useEffect, useReducer, Suspense } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { I18nextProvider } from 'react-i18next';
import { Loading } from '@quizrr/qcl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faCheck, faBan, faEdit, faAngleDown, faAngleRight, faAngleLeft, faArrowRight, faSort, faSortUp, faSortDown, faCaretUp, faCaretDown, faExclamationTriangle, faFilter, faExternalLinkAlt, faList, faArrowLeft, faPlus, faTrashAlt, faEllipsisV, faCaretLeft, faCaretRight, faStar, faSave, faSpinner, faMinus, faArrowCircleUp, faArrowCircleDown, faArrowCircleRight, faQuestion, faLink, faUser, faCalendarAlt, faExclamationCircle, faAngleUp, faCheckCircle, faClock, faFileAlt, faExpandAlt, faCompressAlt, faEnvelope, faTimesCircle, faPaperPlane, faQrcode, faUserPlus, faUsers, faPlusSquare, faMinusSquare, faCloudUploadAlt, faArrowUp, faArrowDown, faUnlock, faLock, faDotCircle, faDownload, faCopy, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { AuthContext, authReducer, initialAuthState, onAuthenticated, onAuthenticating, onAuthenticationFailed } from './data/contexts/AuthContext';
import { SettingsProvider } from './data/contexts/SettingsContext';
import { SupplierProvider } from './data/contexts/SupplierContext';
import { checkIsDemo, getDemoAccountId, getDemoRole } from './utils/demoMode';
import { getCurrentAccessToken, post } from './utils';
import i18n, { initLocales } from './i18n';
import { InternalEventTracker, EventTracker, PostHog } from './utils/eventTracker';
import { Categories } from './data/constants/trackingEvents';
library.add(faTimes, faCheck, faCheckCircle, faBan, faEdit, faAngleDown, faAngleRight, faAngleLeft, faArrowRight, faSort, faSortUp, faSortDown, faCaretUp, faCaretDown, faExclamationTriangle, faFilter, faExternalLinkAlt, faList, faArrowLeft, faPlus, faTrashAlt, faEllipsisV, faCaretLeft, faCaretRight, faStar, faSave, faSpinner, faMinus, faArrowCircleUp, faArrowCircleDown, faArrowCircleRight, faQuestion, faLink, faUser, faCalendarAlt, faExclamationCircle, faAngleUp, faCheckCircle, faClock, faFileAlt, faExpandAlt, faCompressAlt, faEnvelope, faTimesCircle, faPaperPlane, faQrcode, faUserPlus, faUsers, faPlusSquare, faMinusSquare, faCloudUploadAlt, faArrowUp, faArrowDown, faUnlock, faLock, faDotCircle, faDownload, faCopy, faEllipsisH);
var AppProviders = function AppProviders(_ref) {
    var children = _ref.children;
    var _useReducer = useReducer(authReducer, initialAuthState), _useReducer2 = _slicedToArray(_useReducer, 2), state = _useReducer2[0], dispatch = _useReducer2[1];
    useEffect(function () {
        var accessToken = getCurrentAccessToken();
        var verifyToken = function () {
            var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
                var strategy, response, user;
                return _regeneratorRuntime.wrap(function _callee$(_context) {
                    while (1)
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.prev = 0;
                                strategy = 'jwt';
                                _context.next = 4;
                                return post({
                                    endpoint: 'auth',
                                    payload: {
                                        strategy: strategy,
                                        accessToken: accessToken
                                    },
                                    withAuth: false
                                });
                            case 4:
                                response = _context.sent;
                                user = {
                                    id: response.user._id,
                                    accounts: checkIsDemo() ? [{
                                            _id: getDemoAccountId(),
                                            role: getDemoRole()
                                        }] : response.user.accounts,
                                    email: response.user.email,
                                    name: response.user.name,
                                    role: response.user.role
                                };
                                InternalEventTracker.updateContext({
                                    user: user
                                });
                                InternalEventTracker.captureEvent({
                                    event: 'signin',
                                    category: Categories.authentication,
                                    payload: {
                                        strategy: strategy
                                    }
                                });
                                PostHog.identify(user);
                                Sentry.setUser({
                                    id: user.id,
                                    email: user.email
                                });
                                dispatch(onAuthenticated({
                                    user: user,
                                    accessToken: response.accessToken
                                }));
                                _context.next = 18;
                                break;
                            case 13:
                                _context.prev = 13;
                                _context.t0 = _context["catch"](0);
                                Sentry.captureException(_context.t0);
                                EventTracker.error(Categories.authentication, {
                                    description: _context.t0
                                });
                                dispatch(onAuthenticationFailed());
                            case 18:
                            case "end":
                                return _context.stop();
                        }
                }, _callee, null, [[0, 13]]);
            }));
            return function verifyToken() {
                return _ref2.apply(this, arguments);
            };
        }();
        if (accessToken) {
            dispatch(onAuthenticating({}));
            verifyToken();
        }
        else {
            dispatch(onAuthenticationFailed());
        }
    }, []);
    useEffect(function () {
        initLocales();
    }, []);
    return React.createElement(Suspense, {
        fallback: React.createElement(Loading, {
            height: "100%"
        })
    }, React.createElement(I18nextProvider, {
        i18n: i18n
    }, React.createElement(AuthContext.Provider, {
        value: {
            state: state,
            dispatch: dispatch
        }
    }, React.createElement(SettingsProvider, null, React.createElement(SupplierProvider, null, children)))));
};
AppProviders.propTypes = {
    children: PropTypes.node.isRequired
};
export default AppProviders;
