import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { PAGE_WIDTH, PAGE_HEIGHT } from '../components/reports/Page';
var sleeper = function sleeper(ms) {
    return new Promise(function (resolve) {
        return setTimeout(function () {
            return resolve();
        }, ms);
    });
};
var printDocument = function () {
    var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(_ref) {
        var rootNode, documentName, doc, reportWrapper, printNodes, canvases;
        return _regeneratorRuntime.wrap(function _callee$(_context) {
            while (1)
                switch (_context.prev = _context.next) {
                    case 0:
                        rootNode = _ref.rootNode, documentName = _ref.documentName;
                        doc = new jsPDF({
                            orientation: 'landscape',
                            unit: 'mm',
                            format: 'a4',
                            putOnlyUsedFonts: true,
                            compress: true
                        });
                        reportWrapper = document.getElementById(rootNode);
                        printNodes = reportWrapper.childNodes;
                        _context.next = 6;
                        return sleeper(2000);
                    case 6:
                        _context.next = 8;
                        return Promise.all(Array.from(printNodes).map(function (node) {
                            return html2canvas(node, {
                                backgroundColor: '#ffffff',
                                scale: 2,
                                width: PAGE_WIDTH,
                                height: PAGE_HEIGHT
                            });
                        }));
                    case 8:
                        canvases = _context.sent;
                        canvases.forEach(function (canvas) {
                            var img = canvas.toDataURL('image/jpeg', 0.5);
                            doc.addPage();
                            doc.addImage(img, 'JPEG', 0, 0, 297, 210, '', 'SLOW');
                        });
                        doc.deletePage(1);
                        doc.save("".concat(documentName, ".pdf"));
                    case 12:
                    case "end":
                        return _context.stop();
                }
        }, _callee);
    }));
    return function printDocument(_x) {
        return _ref2.apply(this, arguments);
    };
}();
export default printDocument;
