import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
export var downloadFilesUrlAsZip = function () {
    var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(_ref) {
        var fileUrls, zipName, zipSubFolderName, zip, folder, filePromises, content;
        return _regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1)
                switch (_context2.prev = _context2.next) {
                    case 0:
                        fileUrls = _ref.fileUrls, zipName = _ref.zipName, zipSubFolderName = _ref.zipSubFolderName;
                        zip = new JSZip();
                        folder = zip.folder(zipSubFolderName);
                        filePromises = fileUrls.map(function () {
                            var _ref3 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(file) {
                                var response, blob;
                                return _regeneratorRuntime.wrap(function _callee$(_context) {
                                    while (1)
                                        switch (_context.prev = _context.next) {
                                            case 0:
                                                _context.next = 2;
                                                return fetch(file.url);
                                            case 2:
                                                response = _context.sent;
                                                _context.next = 5;
                                                return response.blob();
                                            case 5:
                                                blob = _context.sent;
                                                folder === null || folder === void 0 ? void 0 : folder.file("".concat(file.fileName, ".pdf"), blob);
                                            case 7:
                                            case "end":
                                                return _context.stop();
                                        }
                                }, _callee);
                            }));
                            return function (_x2) {
                                return _ref3.apply(this, arguments);
                            };
                        }());
                        _context2.next = 6;
                        return Promise.all(filePromises);
                    case 6:
                        _context2.next = 8;
                        return zip.generateAsync({
                            type: 'blob'
                        });
                    case 8:
                        content = _context2.sent;
                        saveAs(content, "".concat(zipName, ".zip"));
                    case 10:
                    case "end":
                        return _context2.stop();
                }
        }, _callee2);
    }));
    return function downloadFilesUrlAsZip(_x) {
        return _ref2.apply(this, arguments);
    };
}();
