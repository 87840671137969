import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime/regenerator";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import axios from 'axios';
import { checkIsAuthenticated, getCurrentAccessToken } from './auth';
var _config = config, writeServiceUrl = _config.writeServiceUrl;
var apiClient = axios.create({
    baseURL: writeServiceUrl,
    headers: {
        'content-type': 'application/json'
    }
});
var getHeaders = function getHeaders(withAuth) {
    var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return _objectSpread(_objectSpread({}, withAuth && checkIsAuthenticated() && {
        authorization: "Bearer ".concat(getCurrentAccessToken())
    }), !!language && {
        'x-language': language
    });
};
export var get = function () {
    var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(_ref) {
        var _ref$baseUrl, baseUrl, endpoint, _ref$withAuth, withAuth, response;
        return _regeneratorRuntime.wrap(function _callee$(_context) {
            while (1)
                switch (_context.prev = _context.next) {
                    case 0:
                        _ref$baseUrl = _ref.baseUrl, baseUrl = _ref$baseUrl === void 0 ? writeServiceUrl : _ref$baseUrl, endpoint = _ref.endpoint, _ref$withAuth = _ref.withAuth, withAuth = _ref$withAuth === void 0 ? true : _ref$withAuth;
                        _context.next = 3;
                        return apiClient.get("".concat(baseUrl, "/").concat(endpoint), {
                            headers: getHeaders(withAuth)
                        });
                    case 3:
                        response = _context.sent;
                        return _context.abrupt("return", response.data);
                    case 5:
                    case "end":
                        return _context.stop();
                }
        }, _callee);
    }));
    return function get(_x) {
        return _ref2.apply(this, arguments);
    };
}();
export var post = function () {
    var _ref4 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(_ref3) {
        var endpoint, payload, _ref3$withAuth, withAuth, _ref3$language, language, _ref3$responseType, responseType, response;
        return _regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1)
                switch (_context2.prev = _context2.next) {
                    case 0:
                        endpoint = _ref3.endpoint, payload = _ref3.payload, _ref3$withAuth = _ref3.withAuth, withAuth = _ref3$withAuth === void 0 ? true : _ref3$withAuth, _ref3$language = _ref3.language, language = _ref3$language === void 0 ? '' : _ref3$language, _ref3$responseType = _ref3.responseType, responseType = _ref3$responseType === void 0 ? 'json' : _ref3$responseType;
                        _context2.next = 3;
                        return apiClient.post(endpoint, payload, {
                            headers: getHeaders(withAuth, language),
                            responseType: responseType
                        });
                    case 3:
                        response = _context2.sent;
                        return _context2.abrupt("return", response.data);
                    case 5:
                    case "end":
                        return _context2.stop();
                }
        }, _callee2);
    }));
    return function post(_x2) {
        return _ref4.apply(this, arguments);
    };
}();
export var put = function () {
    var _ref6 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee3(_ref5) {
        var endpoint, payload, _ref5$withAuth, withAuth, response;
        return _regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1)
                switch (_context3.prev = _context3.next) {
                    case 0:
                        endpoint = _ref5.endpoint, payload = _ref5.payload, _ref5$withAuth = _ref5.withAuth, withAuth = _ref5$withAuth === void 0 ? true : _ref5$withAuth;
                        _context3.next = 3;
                        return apiClient.put(endpoint, payload, {
                            headers: getHeaders(withAuth)
                        });
                    case 3:
                        response = _context3.sent;
                        return _context3.abrupt("return", response.data);
                    case 5:
                    case "end":
                        return _context3.stop();
                }
        }, _callee3);
    }));
    return function put(_x3) {
        return _ref6.apply(this, arguments);
    };
}();
export var patch = function () {
    var _ref8 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee4(_ref7) {
        var endpoint, payload, _ref7$withAuth, withAuth, response;
        return _regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1)
                switch (_context4.prev = _context4.next) {
                    case 0:
                        endpoint = _ref7.endpoint, payload = _ref7.payload, _ref7$withAuth = _ref7.withAuth, withAuth = _ref7$withAuth === void 0 ? true : _ref7$withAuth;
                        _context4.next = 3;
                        return apiClient.patch(endpoint, payload, {
                            headers: getHeaders(withAuth)
                        });
                    case 3:
                        response = _context4.sent;
                        return _context4.abrupt("return", response.data);
                    case 5:
                    case "end":
                        return _context4.stop();
                }
        }, _callee4);
    }));
    return function patch(_x4) {
        return _ref8.apply(this, arguments);
    };
}();
export var remove = function () {
    var _ref10 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee5(_ref9) {
        var endpoint, _ref9$withAuth, withAuth, response;
        return _regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1)
                switch (_context5.prev = _context5.next) {
                    case 0:
                        endpoint = _ref9.endpoint, _ref9$withAuth = _ref9.withAuth, withAuth = _ref9$withAuth === void 0 ? true : _ref9$withAuth;
                        _context5.next = 3;
                        return apiClient["delete"](endpoint, {
                            headers: getHeaders(withAuth)
                        });
                    case 3:
                        response = _context5.sent;
                        return _context5.abrupt("return", response.data);
                    case 5:
                    case "end":
                        return _context5.stop();
                }
        }, _callee5);
    }));
    return function remove(_x5) {
        return _ref10.apply(this, arguments);
    };
}();
