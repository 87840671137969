export var PIE_CHART_DECIMALS = 0;
export var BASELINE_CATEGORIES_ORDERED = ['baseline', 'follow-up'];
export var MIN_BASELINES = 1;
export var MAX_BASELINES = 100;
export var CONTENT_MAX_WIDTH = 1400;
export var HEADER_BAR_HEIGHT = 50;
export var PAGE_HEADER_HEIGHT = 100;
export var LOGO_HEIGHT = 25;
export var MOBILE_MAX_WIDTH = 599;
export var TABLET_MAX_WIDTH = 1022;
export var COOKIE_NAME_QUIZRR_CONSENT = 'quizrr-consent';
export var QUIZRR_SUPPORT_SITE_URL = 'https://support.quizrr.se/en/support/home';
export var FORM_MAX_WIDTH = 650;
export var SPACING = {
    auto: 'auto',
    0: '0',
    baseDown4: '0',
    baseDown3: '4px',
    baseDown2: '8px',
    baseDown1: '12px',
    baseUnit: '16px',
    baseUp1: '20px',
    baseUp2: '24px',
    baseUp3: '28px',
    baseUp4: '32px',
    baseUp5: '36px',
    baseUp6: '40px',
    baseUp7: '44px',
    baseUp8: '48px',
    baseUp9: '52px',
    baseUp10: '56px',
    baseUp11: '60px',
    baseUp12: '64px',
    baseUp13: '68px',
    baseUp14: '72px',
    baseUp15: '76px',
    baseUp16: '80px',
    baseUp17: '84px',
    baseUp18: '88px',
    baseUp19: '92px',
    baseUp20: '96px'
};
export * from './learningModules';
export * from './filter';
export * from './joyride';
export * from './date';
export * from './signup';
export * from './trainingParticipationTypes';
export * from './supplier';
export * from './color';
export * from './team';
