import _createClass from "@babel/runtime/helpers/createClass";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _inherits from "@babel/runtime/helpers/inherits";
function _callSuper(t, o, e) { return o = _getPrototypeOf(o), _possibleConstructorReturn(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], _getPrototypeOf(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try {
    var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () { }));
}
catch (t) { } return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
import { AppError } from './appError';
export var CustomGraphQLError = function (_AppError) {
    function CustomGraphQLError(_ref) {
        var graphQLErrors = _ref.graphQLErrors, operation = _ref.operation;
        var captureException = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        _classCallCheck(this, CustomGraphQLError);
        return _callSuper(this, CustomGraphQLError, ['CustomGraphQLError', graphQLErrors.map(function (_ref2) {
                var message = _ref2.message;
                return message;
            }).join(', '), captureException, {
                operationName: operation.operationName,
                operationQueryKind: operation.query.kind,
                errors: JSON.stringify(graphQLErrors, null, 2)
            }]);
    }
    _inherits(CustomGraphQLError, _AppError);
    return _createClass(CustomGraphQLError);
}(AppError);
