import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
var initialState = {
    invitationInfo: null
};
var SupplierContext = createContext({
    state: initialState,
    setState: function setState() {
        return null;
    }
});
export var SupplierProvider = function SupplierProvider(_ref) {
    var children = _ref.children;
    var _useState = useState(initialState), _useState2 = _slicedToArray(_useState, 2), state = _useState2[0], setState = _useState2[1];
    return React.createElement(SupplierContext.Provider, {
        value: {
            state: state,
            setState: setState
        }
    }, children);
};
export function useSupplierContext() {
    var _useContext = useContext(SupplierContext), state = _useContext.state, setState = _useContext.setState;
    var setInvitationInfo = function setInvitationInfo(invitationInfo) {
        setState(_objectSpread(_objectSpread({}, state), {}, {
            invitationInfo: invitationInfo
        }));
    };
    return _objectSpread(_objectSpread({}, state), {}, {
        setInvitationInfo: setInvitationInfo
    });
}
SupplierProvider.propTypes = {
    children: PropTypes.node.isRequired
};
