import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { JOYRIDE_RUN_PAGES } from '../data/constants';
export var isJoyrideCompleted = function isJoyrideCompleted(pageId) {
    var completedPageIds = JSON.parse(localStorage.getItem(JOYRIDE_RUN_PAGES));
    return completedPageIds && !!completedPageIds.find(function (page) {
        return page === pageId;
    });
};
export var setJoyrideCompleted = function setJoyrideCompleted(pageId) {
    var completedPageIds = JSON.parse(localStorage.getItem(JOYRIDE_RUN_PAGES));
    if (!(completedPageIds !== null && completedPageIds !== void 0 && completedPageIds.find(function (page) {
        return page === pageId;
    }))) {
        localStorage.setItem(JOYRIDE_RUN_PAGES, JSON.stringify(completedPageIds ? [].concat(_toConsumableArray(completedPageIds), [pageId]) : [pageId]));
    }
};
