import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Buffer } from 'buffer';
import { checkIsAuthenticated, clearAuthentication, getDemoAccessToken } from './auth';
export var getDemoAccount = function getDemoAccount() {
    if (!checkIsAuthenticated()) {
        return undefined;
    }
    try {
        var _getDemoAccessToken;
        var _ref = ((_getDemoAccessToken = getDemoAccessToken()) === null || _getDemoAccessToken === void 0 ? void 0 : _getDemoAccessToken.split('.')) || '', _ref2 = _slicedToArray(_ref, 2), base64 = _ref2[1];
        var json = Buffer.from(base64, 'base64').toString();
        var _JSON$parse = JSON.parse(json), demoAccountId = _JSON$parse.demoAccountId, demoRole = _JSON$parse.demoRole;
        return {
            demoAccountId: demoAccountId,
            demoRole: demoRole
        };
    }
    catch (_unused) {
        return undefined;
    }
};
export var disableDemoMode = function disableDemoMode() {
    clearAuthentication({
        clearAccessToken: false
    });
};
export var getDemoAccountId = function getDemoAccountId() {
    var _getDemoAccount;
    return (_getDemoAccount = getDemoAccount()) === null || _getDemoAccount === void 0 ? void 0 : _getDemoAccount.demoAccountId;
};
export var getDemoRole = function getDemoRole() {
    var _getDemoAccount2;
    return (_getDemoAccount2 = getDemoAccount()) === null || _getDemoAccount2 === void 0 ? void 0 : _getDemoAccount2.demoRole;
};
export var checkIsDemo = function checkIsDemo() {
    return !!getDemoAccount();
};
