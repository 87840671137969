import { theme } from '@quizrr/qcl';
export var PerformanceTrendColors = ['#052264', '#215CFD', '#FAB2AF', '#104E1F', '#28A247', '#898684', '#834200', '#D56B00', '#ABC3FF', '#8EDBA2', '#FFD9A1'];
export var PerformanceLevelColors = {
    high: '#D4ECDA',
    medium: '#FFD9A1',
    low: '#FAB2AF'
};
export var PerformanceLevelTextColor = {
    high: '#104E1F',
    medium: '#834200',
    low: '#9e0b05'
};
export var PerformanceDistributionColors = {
    concentration_range_1: '#D9D9D9',
    concentration_range_2: '#B3C7FE',
    concentration_range_3: '#86A6FE',
    concentration_range_4: '#215CFD',
    concentration_range_5: '#023EE3',
    concentration_range_6: '#012EA0'
};
export var byGenderColors = {
    female: theme.colors.blue500,
    male: theme.colors.blue300,
    other: theme.colors.gray300
};
export var byKnowledgeGainColors = {
    gainedKnowledge: theme.colors.blue500,
    notGainedKnowledge: theme.colors.blue300
};
