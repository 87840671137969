import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';
var downloadXLSX = function downloadXLSX(_ref) {
    var sheets = _ref.sheets, sheetNames = _ref.sheetNames, fileName = _ref.fileName;
    var file = xlsx.utils.book_new();
    (sheets.length > 0 ? sheets : [[]]).forEach(function (sheet, index) {
        var worksheet = xlsx.utils.aoa_to_sheet(sheet);
        xlsx.utils.book_append_sheet(file, worksheet, sheets.length > 0 ? sheetNames[index] : 'Sheet1');
    });
    var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var excelBuffer = xlsx.write(file, {
        bookType: 'xlsx',
        type: 'array'
    });
    var xlsxContent = new Blob([excelBuffer], {
        type: fileType
    });
    saveAs(xlsxContent, fileName);
};
export default downloadXLSX;
