import styled from 'styled-components';
import { Block } from '@quizrr/qcl';
export var PAGE_WIDTH = 1123;
export var PAGE_HEIGHT = 793;
var Page = styled(Block).withConfig({
    displayName: "Page",
    componentId: "sc-1e5pf3l-0"
})(["position:relative;width:", "px;height:", "px;margin-bottom:", ";-webkit-print-color-adjust:exact;box-shadow:0 0 10px rgba(0,0,0,0.2);overflow:hidden;page-break-after:always;@page{size:A4 landscape;}@media print{margin-bottom:0;box-shadow:none;border:0;}"], PAGE_WIDTH, PAGE_HEIGHT, function (_ref) {
    var theme = _ref.theme;
    return theme.spacing.baseUnit;
});
export default Page;
