import moment from 'moment';
var getRequiredSessionsPerDay = function getRequiredSessionsPerDay(startDate, endDate, excludedDates, requiredSessionNumber) {
    var totalExcludedDays = excludedDates.map(function (date) {
        return moment(date.untilDate).diff(moment(date.fromDate), 'days') + 1;
    }).reduce(function (acc, val) {
        return acc + val;
    }, 0);
    var trainingPlanDuration = moment(endDate).diff(moment(startDate), 'days') + 1 - totalExcludedDays;
    return requiredSessionNumber / trainingPlanDuration;
};
export default getRequiredSessionsPerDay;
