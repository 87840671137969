import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { isAdmin } from './featureFlags';
export var getUniquesFromSet = function getUniquesFromSet(factories) {
    return Array.from(new Set(factories.map(JSON.stringify))).map(JSON.parse).sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
    });
};
export var getUniqueUserBrands = function getUniqueUserBrands(_ref) {
    var _ref$organizations = _ref.organizations, organizations = _ref$organizations === void 0 ? [] : _ref$organizations, _ref$brands = _ref.brands, brands = _ref$brands === void 0 ? [] : _ref$brands;
    var organizationBrands = organizations.flatMap(function (organization) {
        return organization.brands;
    });
    var allBrands = [].concat(_toConsumableArray(brands), _toConsumableArray(organizationBrands));
    return getUniquesFromSet(allBrands);
};
export var getUniqueUserFactories = function getUniqueUserFactories(_ref2) {
    var _ref2$organizations = _ref2.organizations, organizations = _ref2$organizations === void 0 ? [] : _ref2$organizations, _ref2$brands = _ref2.brands, brands = _ref2$brands === void 0 ? [] : _ref2$brands, _ref2$factories = _ref2.factories, factories = _ref2$factories === void 0 ? [] : _ref2$factories;
    var organizationFactories = organizations.flatMap(function (o) {
        return o.factories.map(function (f) {
            return f;
        });
    });
    var allBrands = getUniqueUserBrands({
        organizations: organizations,
        brands: brands
    });
    var brandAndOrganizationFactories = [].concat(_toConsumableArray(brands), _toConsumableArray(allBrands)).flatMap(function (o) {
        return o.factories.map(function (f) {
            return f;
        });
    });
    var allFactories = [].concat(_toConsumableArray(organizationFactories), _toConsumableArray(brandAndOrganizationFactories), _toConsumableArray(factories));
    return getUniquesFromSet(allFactories.filter(function (f) {
        return Boolean(f.market);
    }));
};
export var getTrainingPlanPermissions = function getTrainingPlanPermissions(_ref3) {
    var data = _ref3.data, factoryId = _ref3.factoryId, role = _ref3.role;
    var userFactories = getUniqueUserFactories(data);
    var read = userFactories.map(function (f) {
        return f._id.toString();
    }).includes(factoryId.toString());
    return {
        read: read,
        edit: isAdmin(role),
        "delete": isAdmin(role)
    };
};
export default getUniqueUserFactories;
