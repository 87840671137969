import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { createContext, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { checkIsDemo, clearAuthentication, storeAuthentication } from '../../utils';
import { InternalEventTracker, PostHog } from '../../utils/eventTracker';
export var initialAuthState = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null
};
export var onAuthenticating = function onAuthenticating(_ref) {
    var _ref$user = _ref.user, user = _ref$user === void 0 ? {} : _ref$user;
    return {
        type: 'SIGNIN_REQUEST',
        payload: {
            user: user
        }
    };
};
export var onAuthenticated = function onAuthenticated(_ref2) {
    var user = _ref2.user, accessToken = _ref2.accessToken;
    return {
        type: 'SIGNIN_SUCCESS',
        payload: {
            user: user,
            accessToken: accessToken
        }
    };
};
export var onAuthenticationFailed = function onAuthenticationFailed() {
    return {
        type: 'AUTH_FAILED'
    };
};
export var onNewAccountAdded = function onNewAccountAdded(_ref3) {
    var account = _ref3.account;
    return {
        type: 'ADD_NEW_ACCOUNT',
        payload: {
            account: account
        }
    };
};
export var onUpdateAccounts = function onUpdateAccounts(_ref4) {
    var accounts = _ref4.accounts;
    return {
        type: 'UPDATE_ACCOUNTS',
        payload: {
            accounts: accounts
        }
    };
};
export var onUpdateAccount = function onUpdateAccount(_ref5) {
    var account = _ref5.account;
    return {
        type: 'UPDATE_ACCOUNT',
        payload: {
            account: account
        }
    };
};
export var onSignout = function onSignout() {
    return {
        type: 'SIGNOUT_SUCCESS'
    };
};
export var authReducer = function authReducer(state, _ref6) {
    var type = _ref6.type, _ref6$payload = _ref6.payload, payload = _ref6$payload === void 0 ? {} : _ref6$payload;
    switch (type) {
        case 'SIGNIN_REQUEST':
            return _objectSpread(_objectSpread({}, state), {}, {
                isAuthenticating: true,
                user: payload.user
            });
        case 'SIGNIN_SUCCESS':
            if (!checkIsDemo()) {
                storeAuthentication({
                    accessToken: payload.accessToken
                });
            }
            return _objectSpread(_objectSpread({}, state), {}, {
                isAuthenticated: true,
                isAuthenticating: false,
                user: payload.user
            });
        case 'SIGNOUT_SUCCESS':
            clearAuthentication();
            PostHog.resetUser();
            Sentry.setUser(null);
            InternalEventTracker.updateContext({
                user: undefined
            });
            window.location.reload();
            return initialAuthState;
        case 'AUTH_FAILED':
            clearAuthentication();
            return _objectSpread(_objectSpread({}, initialAuthState), {}, {
                isAuthenticating: false
            });
        case 'ADD_NEW_ACCOUNT':
            return _objectSpread(_objectSpread({}, state), {}, {
                user: _objectSpread(_objectSpread({}, state.user), {}, {
                    accounts: [].concat(_toConsumableArray(state.user.accounts), [payload.account])
                })
            });
        case 'UPDATE_ACCOUNTS':
            return _objectSpread(_objectSpread({}, state), {}, {
                user: _objectSpread(_objectSpread({}, state.user), {}, {
                    accounts: payload.accounts
                })
            });
        case 'UPDATE_ACCOUNT':
            return _objectSpread(_objectSpread({}, state), {}, {
                user: _objectSpread(_objectSpread({}, state.user), {}, {
                    accounts: state.user.accounts.map(function (acc) {
                        return acc._id === payload.account._id ? payload.account : acc;
                    })
                })
            });
        default:
            return state;
    }
};
export var getAccessTokenFromHash = function getAccessTokenFromHash(hash) {
    var _hash$split = hash.split('='), _hash$split2 = _slicedToArray(_hash$split, 2), accessToken = _hash$split2[1];
    var _window$location$href = window.location.href.split('#'), _window$location$href2 = _slicedToArray(_window$location$href, 1), nextUrl = _window$location$href2[0];
    storeAuthentication({
        accessToken: accessToken
    });
    return nextUrl;
};
export var AuthContext = createContext();
export function useAuth() {
    return useContext(AuthContext);
}
