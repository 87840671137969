import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
export var useAsyncFunction = function useAsyncFunction(asyncFunction, dependencies, cleanup) {
    var _useState = useState(true), _useState2 = _slicedToArray(_useState, 2), loading = _useState2[0], setLoading = _useState2[1];
    var _useState3 = useState(null), _useState4 = _slicedToArray(_useState3, 2), data = _useState4[0], setData = _useState4[1];
    var _useState5 = useState(null), _useState6 = _slicedToArray(_useState5, 2), error = _useState6[0], setError = _useState6[1];
    var isMountedRef = useRef(false);
    var performAction = function () {
        var _ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
            var response;
            return _regeneratorRuntime.wrap(function _callee$(_context) {
                while (1)
                    switch (_context.prev = _context.next) {
                        case 0:
                            _context.prev = 0;
                            setData(null);
                            setError(null);
                            _context.next = 5;
                            return asyncFunction();
                        case 5:
                            response = _context.sent;
                            if (isMountedRef.current) {
                                setData(response);
                                setLoading(false);
                            }
                            _context.next = 13;
                            break;
                        case 9:
                            _context.prev = 9;
                            _context.t0 = _context["catch"](0);
                            Sentry.captureException(_context.t0);
                            if (isMountedRef.current) {
                                setError(_context.t0);
                                setLoading(false);
                            }
                        case 13:
                        case "end":
                            return _context.stop();
                    }
            }, _callee, null, [[0, 9]]);
        }));
        return function performAction() {
            return _ref.apply(this, arguments);
        };
    }();
    var refresh = function refresh() {
        setLoading(true);
        setData(null);
        setError(null);
        performAction();
    };
    useEffect(function () {
        isMountedRef.current = true;
        setLoading(true);
        setData(null);
        setError(null);
        performAction();
        return function () {
            isMountedRef.current = false;
            if (cleanup) {
                cleanup();
            }
        };
    }, dependencies || []);
    return {
        loading: loading,
        data: data,
        error: error,
        refresh: refresh
    };
};
