var resolveKey = function resolveKey(obj, key) {
    return key.split('.').reduce(function (p, c) {
        return p && p[c] || null;
    }, obj);
};
var sortByKey = function sortByKey(_ref) {
    var key = _ref.key, _ref$order = _ref.order, order = _ref$order === void 0 ? 'desc' : _ref$order;
    return function (a, b) {
        if (!key.includes('.')) {
            if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
                return 0;
            }
        }
        var valA;
        var valB;
        if (key.includes('.')) {
            valA = resolveKey(a, key);
            valB = resolveKey(b, key);
        }
        else {
            valA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            valB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
        }
        var comparison = 0;
        if (valA > valB) {
            comparison = 1;
        }
        else if (valA < valB) {
            comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
    };
};
export default sortByKey;
