import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { P, theme } from '@quizrr/qcl';
import CookieConsent from 'react-cookie-consent';
import I18nLink from '../components/I18nLink';
import { EventTracker } from '../utils/eventTracker';
import { COOKIE_NAME_QUIZRR_CONSENT } from '../data/constants';
import { Actions, Categories } from '../data/constants/trackingEvents';
import { shouldPageHideCookieConsent } from '../utils';
var buttonStyles = {
    paddingInline: theme.spacing.baseUp2,
    paddingBlock: theme.spacing.baseDown2,
    borderRadius: theme.spacing.baseUp3,
    backgroundColor: theme.colors.blue500,
    color: theme.colors.white
};
var CookieConsentBanner = function CookieConsentBanner() {
    var _useTranslation = useTranslation(), t = _useTranslation.t;
    var _useLocation = useLocation(), pathname = _useLocation.pathname;
    if (shouldPageHideCookieConsent(pathname)) {
        return null;
    }
    return React.createElement(CookieConsent, {
        cookieName: COOKIE_NAME_QUIZRR_CONSENT,
        enableDeclineButton: true,
        buttonText: t('global:cookie_consent.accept'),
        buttonStyle: buttonStyles,
        onAccept: function onAccept() {
            EventTracker.grantConsent();
            EventTracker.captureEvent({
                event: Actions.cookieConsent.accept,
                category: Categories.cookieConsent,
                payload: {
                    pathname: pathname
                }
            });
        },
        onDecline: function onDecline() {
            EventTracker.captureEvent({
                event: Actions.cookieConsent.decline,
                category: Categories.cookieConsent,
                payload: {
                    pathname: pathname
                }
            });
        },
        declineButtonText: t('global:cookie_consent.reject'),
        declineButtonStyle: _objectSpread(_objectSpread({}, buttonStyles), {}, {
            backgroundColor: 'transparent',
            color: theme.colors.gray700
        }),
        style: {
            backgroundColor: theme.colors.white,
            boxShadow: '0 14px 20px 2px rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            alignItems: 'center'
        },
        flipButtons: true
    }, React.createElement(P, {
        fontSize: "baseDown1",
        isBold: true,
        mb: "0"
    }, React.createElement(Trans, {
        i18nKey: "global:cookie_consent.description",
        t: t,
        components: [React.createElement(I18nLink, {
                key: "privacy-policy-link",
                link: "https://www.quizrr.se/privacy-policy"
            })]
    })));
};
export default CookieConsentBanner;
