var getAppTrainingURLParams = function getAppTrainingURLParams(trainingParams, importSettings) {
    var appSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var encodedTrainingParams = window.btoa(JSON.stringify(trainingParams));
    var encodedSettings = window.btoa(encodeURIComponent(JSON.stringify(importSettings)));
    var hideIntroVideo = appSettings.hideIntroVideo, skipOnboarding = appSettings.skipOnboarding;
    var skipOnboardingURLParam = skipOnboarding ? "&skipOnboarding=".concat(skipOnboarding) : '';
    return "?qrcode=".concat(encodedTrainingParams, "&settings=").concat(encodedSettings).concat(hideIntroVideo ? "&hideIntroVideo=".concat(hideIntroVideo) : '').concat(skipOnboardingURLParam);
};
export var getAppTrainingUrl = function getAppTrainingUrl(trainingParams, importSettings) {
    var appSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return "".concat(config.webBasedTrainingUrl, "/app/").concat(getAppTrainingURLParams(trainingParams, importSettings, appSettings));
};
export var getWebBaseTrainingUrl = function getWebBaseTrainingUrl(trainingParams, importSettings) {
    var appSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return "".concat(window.location.origin, "/training/").concat(getAppTrainingURLParams(trainingParams, importSettings, appSettings));
};
export var getDeepLinkingTrainingUrl = function getDeepLinkingTrainingUrl(trainingParams, importSettings) {
    var appSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return "".concat(config.deepLinkingTrainingUrl, "/app/").concat(getAppTrainingURLParams(trainingParams, importSettings, appSettings));
};
