import _typeof from "@babel/runtime/helpers/typeof";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export var getJobLevelsFromDynamicView = function getJobLevelsFromDynamicView(dynamicView) {
    var dynamicViewFilters = dynamicView._filters._filters;
    var _dynamicViewFilters$f = dynamicViewFilters.find(function (o) {
        return o.name === 'jobLevel';
    }), _dynamicViewFilters$f2 = _dynamicViewFilters$f.options, options = _dynamicViewFilters$f2 === void 0 ? [] : _dynamicViewFilters$f2;
    return _toConsumableArray(new Set(options.map(function (o) {
        if (o && _typeof(o) === 'object' && !Array.isArray(o)) {
            return o.value;
        }
        return o;
    })));
};
