import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
export var formatQuestions = function formatQuestions(_ref) {
    var allQuestions = _ref.allQuestions, _ref$questionsLanguag = _ref.questionsLanguage, questionsLanguage = _ref$questionsLanguag === void 0 ? 'en' : _ref$questionsLanguag, hasKey = _ref.hasKey;
    if (hasKey) {
        return Object.keys(allQuestions).reduce(function (acc, questionKey) {
            var question = allQuestions[questionKey];
            return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, questionKey, {
                id: questionKey,
                firebaseKey: question.firebaseKey,
                text: question.text[questionsLanguage],
                answers: Object.keys(question.answers).map(function (answerKey) {
                    return {
                        id: answerKey,
                        text: question.answers[answerKey].text[questionsLanguage]
                    };
                }),
                correctAnswers: Object.keys(question.correctAnswers).filter(function (answerKey) {
                    return question.correctAnswers[answerKey];
                }).map(function (answerKey) {
                    return {
                        id: answerKey,
                        text: question.answers[answerKey].text[questionsLanguage]
                    };
                })
            }));
        }, {});
    }
    return allQuestions.reduce(function (acc, question) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, "".concat(question.learningModule, "_").concat(question.level, "_").concat(question.number), {
            id: question.number,
            text: question.question,
            correctAnswers: question.answers.filter(function (answer) {
                return answer.correct;
            }).map(function (answer) {
                return {
                    text: answer.answer
                };
            })
        }));
    }, {});
};
export var formatQuestionsBaseline = function formatQuestionsBaseline(_ref2) {
    var allQuestions = _ref2.allQuestions;
    return allQuestions.reduce(function (acc, question) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, "".concat(question.category, "_").concat(question.level), _objectSpread(_objectSpread({}, acc["".concat(question.category, "_").concat(question.level)]), {}, _defineProperty({}, "question_".concat(question.number), {
            id: question.number,
            type: question.type,
            text: question.question,
            answers: question.answers,
            correctAnswers: question.answers.filter(function (answer) {
                return answer.correct;
            }).map(function (answer) {
                return {
                    text: answer.answer
                };
            })
        }))));
    }, {});
};
export var formatQuestionsOfNewBaseline = function formatQuestionsOfNewBaseline(_ref3) {
    var questionKeys = _ref3.questionKeys, questions = _ref3.questions, _ref3$questionsLangua = _ref3.questionsLanguage, questionsLanguage = _ref3$questionsLangua === void 0 ? 'en' : _ref3$questionsLangua;
    return questionKeys.reduce(function (acc, questionKey) {
        var question = questions[questionKey];
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, questionKey, {
            id: questionKey,
            type: question.type,
            text: question.text[questionsLanguage],
            answers: question.answers,
            correctAnswers: Object.keys(question.correctAnswers).map(function (answerKey) {
                return {
                    text: question.answers[answerKey].text[questionsLanguage]
                };
            })
        }));
    }, {});
};
