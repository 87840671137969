import PropTypes from 'prop-types';
export var Filter = PropTypes.object;
export var FilterOptions = PropTypes.oneOfType([PropTypes.object, PropTypes.array]);
export var FilterOptionValues = PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]));
export var FilterOption = PropTypes.object;
export var View = PropTypes.object;
export var DynamicViewType = PropTypes.shape({
    filters: PropTypes.arrayOf(Filter),
    views: PropTypes.arrayOf(View)
});
export var ViewName = PropTypes.string;
export var FilterValues = PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string, PropTypes.array]));
export var DynamicViewMergeOptions = PropTypes.object;
export var DynamicFiltersMergeOptions = PropTypes.object;
