import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import * as htmlToImage from 'html-to-image';
export var QRCODE_DIMENSION = 450;
export var prepareQrcodeBase64Image = function () {
    var _ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
        var qrcodeElementId, qrcodeElement, imageBase64, _args = arguments;
        return _regeneratorRuntime.wrap(function _callee$(_context) {
            while (1)
                switch (_context.prev = _context.next) {
                    case 0:
                        qrcodeElementId = _args.length > 0 && _args[0] !== undefined ? _args[0] : 'QR_CODE';
                        qrcodeElement = document.getElementById(qrcodeElementId);
                        _context.next = 4;
                        return htmlToImage.toPng(qrcodeElement);
                    case 4:
                        _context.next = 6;
                        return htmlToImage.toPng(qrcodeElement);
                    case 6:
                        _context.next = 8;
                        return htmlToImage.toPng(qrcodeElement);
                    case 8:
                        _context.next = 10;
                        return htmlToImage.toPng(qrcodeElement);
                    case 10:
                        imageBase64 = _context.sent;
                        return _context.abrupt("return", imageBase64);
                    case 12:
                    case "end":
                        return _context.stop();
                }
        }, _callee);
    }));
    return function prepareQrcodeBase64Image() {
        return _ref.apply(this, arguments);
    };
}();
export var downloadQRCode = function () {
    var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(downloadedQrCodeName) {
        var qrcodeElementId, imageBase64, link, _args2 = arguments;
        return _regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1)
                switch (_context2.prev = _context2.next) {
                    case 0:
                        qrcodeElementId = _args2.length > 1 && _args2[1] !== undefined ? _args2[1] : 'QR_CODE';
                        _context2.next = 3;
                        return prepareQrcodeBase64Image(qrcodeElementId);
                    case 3:
                        imageBase64 = _context2.sent;
                        link = document.createElement('a');
                        link.download = downloadedQrCodeName;
                        link.href = imageBase64;
                        link.click();
                    case 8:
                    case "end":
                        return _context2.stop();
                }
        }, _callee2);
    }));
    return function downloadQRCode(_x) {
        return _ref2.apply(this, arguments);
    };
}();
