import { createGlobalStyle } from 'styled-components';
import OpenSansRegular from '../assets/fonts/open-sans/OpenSans-Regular.ttf';
import OpenSansMedium from '../assets/fonts/open-sans/OpenSans-Medium.ttf';
var GlobalStyleVietnamese = createGlobalStyle(["@font-face{font-family:'Open Sans';src:url(", ");font-weight:", ";}@font-face{font-family:'Open Sans';src:url(", ");font-weight:bold;}@font-face{font-family:'Open Sans';src:url(", ");font-weight:", ";}html.vi{font-family:'Open Sans';}body.vi{font-family:'Open Sans';h1,h2,h3,h4,h5,h6{font-family:'Open Sans';}span,div{font-family:'Open Sans';}p,label{font-family:'Open Sans';}button,a{font-family:'Open Sans';}}"], OpenSansRegular, function (_ref) {
    var theme = _ref.theme;
    return theme.fontWeights.normal;
}, OpenSansMedium, OpenSansMedium, function (_ref2) {
    var theme = _ref2.theme;
    return theme.fontWeights.bold;
});
export default GlobalStyleVietnamese;
