import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime/regenerator";
var downloadCsv = function () {
    var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(_ref) {
        var content, fileName, csvContent, encodedUri, link;
        return _regeneratorRuntime.wrap(function _callee$(_context) {
            while (1)
                switch (_context.prev = _context.next) {
                    case 0:
                        content = _ref.content, fileName = _ref.fileName;
                        csvContent = "data:text/csv;charset=utf-8,\uFEFF".concat(content);
                        encodedUri = encodeURI(csvContent);
                        link = document.createElement('a');
                        link.href = encodedUri;
                        link.download = fileName;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    case 10:
                    case "end":
                        return _context.stop();
                }
        }, _callee);
    }));
    return function downloadCsv(_x) {
        return _ref2.apply(this, arguments);
    };
}();
export default downloadCsv;
