import { Categories } from '../../data/constants/trackingEvents';
import PostHog from './PostHog';
import InternalEventTracker from './InternalEventTracker';
export var grantConsent = function grantConsent() {
    PostHog.grantConsent();
};
export var captureEvent = function captureEvent(event) {
    InternalEventTracker.captureEvent(event);
    PostHog.capture(event.event, event.payload);
};
export var pageView = function pageView(payload) {
    InternalEventTracker.captureEvent({
        event: 'page_view',
        category: Categories.pageViews,
        payload: payload
    });
    PostHog.pageView();
};
export var error = function error(category, payload) {
    InternalEventTracker.captureEvent({
        event: 'exception',
        category: category,
        payload: payload
    });
};
